.breadcrumbs {
		list-style:none;
		display: inline-block;
		font-weight:400;
		color:$primaryBlue;
		a {
			font-weight:600;
			color:$primaryBlue;
			text-decoration: underline;
		}
	
}

.fuling {
	width:100%;
	height:20px;
	@include below($s) {
		display: none;
	}
}