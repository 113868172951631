.play-col {
	margin:12px 0;
	position: relative;
	padding-top:30%;
	@include below($s) {
		padding-top:50%;
	}
}

.play-frame {
	width:100%;
	height:100%;
	top:0;
	position: absolute;
}