.pros-overlay {
    width:100%;
    height: 100%;
    top:0;
    left:0;
    position: fixed;
    background:$primaryBlue;
    opacity:0;
    pointer-events:none;
    transition:opacity .18s;
    background-size:cover;
    background-position:center;
    &.visible {
        opacity:1;
        pointer-events:initial;
        z-index:99;
    }
}
.pros-overlay-filter {
    position:absolute;
    z-index:0;
    background:$primaryBlue;
    opacity:0.7;
    width:100%;
    height:100%;
}
.pros-overlay-content {
    position: absolute;
    top:50%;
    left: 50%;
    transform:translate(-50%, -50%);
    width:724px;
    text-align:center;
    min-height:300px;
    display: flex;
    align-items:center;
    justify-content: center;
    overflow:hidden;
    @include below($m) { 
        width:80%;
        img {
            width:90%;
        }
    }
    @include below($s) {
        width:100%;
        img {
            width:95%;
        }
    }
}
.pros-overlay-text {
    font-size:25px;
    line-height:36px;
    color:white;
    margin-top:24px;
    
    &.quote {
        blockquote {
            margin:0;
        }
        blockquote p {
            font-style:italic;
            font-size:25px;
            line-height:36px;
            color:white;
            font-weight:normal;
            text-transform:none;
            
            @include below($s) {
                font-size:16px;
                line-height:27px;
            }
        }
        p {
            font-size:18px;
            font-weight:bold;
            color:$black;
            text-transform:uppercase;
            margin-top:24px;
            @include below($s) {
                font-size:12px;
                line-height:23px;
                width:100%;
            }
        }
    }
    @include below($m) {
        font-size:21px;
        line-height:32px;
    }
    @include below($s) {
        font-size:16px;
        line-height:27px;
        width:100%;
        padding:0 24px;
    }
}
.pros-overlay .holder {
    min-height:100vh;
}
.pros-overlay-image {
    max-width:70%;
    margin:0 auto;
}
.pros-overlay-video {
    max-width:90%;
    margin:0 auto;
}
.pros-overlay-exit {
    position: absolute;
    top:70px;
    right:0;
}
.pros-overlay-box {
    opacity:1;
    transform:translateY(0%);
    transition:.24s all;
}
.pros-overlay-navigation {
    width:100%;
    position: absolute;
    left:0;
    top:50%;
    transform:translateY(-50%);
    a {
        position: absolute;
        img {
            width:32px;
        }
    }
    .js-prev-pro {
        left:0;
        @include below($m) {
            left:2%;
        }
        img {
            transform:rotate(180deg);
        }
    }
    
    .js-next-pro {
        right:0;
        @include below($m) {
            right:2%;
        }
    }
    @include below($s) {
        transform:translate(-50%, 0%);
        left:50%;
        width:50%;
        top:initial;
        bottom:15%;
    }
}
.pros-overlay-share {
    position: absolute;
    width:100%;
    bottom:5%;
    text-align:left;
    left:0;
    img {
        width:60px;
    }
    .fb-share-button {
        position: absolute;
        
        left: 50%;
        transform:translateX(-50%);
        top:0;
        span, a {
            width:60px !important;
        height: 60px!important;
        }
    }
}