.related-articles {
	display: block;
	width:100%;
	margin-top:12px;
	@include below($s) {
		margin-top:0;
	}
}

.related-article {
	background:$primaryBlue;
	display: block;
	text-align:center;
	padding:50px 40px;
	margin-top:24px;
}

.related-article-title {
	text-decoration: none;
	color:white;
	font-weight:600;
	margin-bottom:24px;
}