.urgent-contact {
	display: inline-block;
	background:$primaryBlue;
	padding:5px 10px;
	margin-top:5px;
}

.contact-maps {
	margin-top:12px;

	.contact-office{
		margin-top: 12px;
		margin-bottom: 12px;
	}
}

.contact-map {
	width:100%;
	height:auto;
}
.employee-list {
	position: relative;
	display: block;
}

.js-employee-filter {
	position: relative;
	font-size:18px;
	font-weight:$medium;
	&:after {
		content:'Filtrera medarbetare';
	}
	&.active {
		background:$primaryBlue;
		color:white;
		opacity:1;
		padding:12px 24px 12px 0;
		&:after {
			content:'Stäng filtrering';
			text-decoration: underline;
		}
		&:before {
			content:'';
			width:20px;
			background:$primaryBlue;
			height:100%;
			position:absolute;
			top:0;
			bottom:0;
			left:-20px;
			display: block;
			visibility: visible;
		}
	}
}

.contact-search-btn-wrap {
	display:flex;
	align-items: flex-end;
}

.employee-search-contact-wrapper {
	flex-direction: column;

	.col-8 {
		padding: 2rem 0 0;
	}
}

.employee-search-wrap {
	position: relative;
	width: 100%;
	.icon-search {
		position: absolute;
		top: 15px;
		right: 15px;
		width: 22px;
		height: 22px;
		pointer-events: none;
	}
}
.employee-search {
	width: 100%;
    background: #e5e5e5;
    padding: 14px 20px;
	text-align: left;
	border-radius: 4px;
	color: #4A4A4A;

	&::-webkit-input-placeholder {
		color: #4A4A4A;
	}
}
.employee-wrap {
	display:block;
	position: relative;
	margin-top:24px;
	ul.list {
		display:flex;
		flex-wrap:wrap;
		margin-left: -34px !important;
		margin-right: -34px !important;
	}
}
.employee-image {
	max-width:80%;
	margin:0 auto;
	height:auto;
	display: block;
}

.employee-image-article {
	max-width:273px;
	height:auto;
	display: block;

	@include below($m) {
		max-width:60%;
	}
}

.press-image-item {
	list-style: none;
    position: relative;
    margin: 12px 0;
    width: 47%;

	@include below($s) {
		width:100% !important;
	}
}

.employee-item {
	list-style: none;
	position: relative;
	margin: 12px 0;
	padding-left: 34px;

	&.no-line {
		width: 50%;

		&:before {
			display: none;
		}
	}
	width:33.33%;
	@include below($s) {
		width:100% !important;
	}
	&.hidden {
		display: none;
	}
	&.no-show {
		display: none;
	}
}
.employee-cat {
	font-size:14px;
	font-weight:$medium;
	background:$black;
	color:white;
	line-height:14px;
	padding:2px;
	display: inline-block;
	text-transform: capitalize;
	i {
		font-size:14px;
		font-weight:$medium;
		font-style:normal;
	}
}
.employee-info {
	position: relative;
	width:100%;
	overflow:hidden;
}
.employee-mail {
	word-break: break-all;
}

.show-all-contacts {

	&.hide {
		display: none;
	}
}
#no-match {
	display: none;
}

/* Page menu */
.page-menu {

	a {
		font-size: 18px;
		color: #4A4A4A;
		display: inline-block;
		padding: 4px 0;
		text-decoration: underline;

		&:hover {
			color: $black;
		}
	}
}

.anchor {
	position: absolute;
	top: -150px;

	@include below($l) {
		top: -70px;
	}
}


/* Contact form */
.contact-form-holder {
	background: $primaryBlue;
	padding: 40px 25px;

	.top-text {
		padding-left: 10px;
	}

	h3, p, label {
		color: $white;
		font-weight: $semibold;
	}

	.form-field {
		margin-top: 10px;
		width: calc(100%/2 - 15px);
		margin-right: 30px !important;

		&:nth-child(even) {
			margin-right: 0 !important;
		}

		input {
			background-color: rgba(255,255,255,.6) !important;
			border-radius: 4px !important;
			padding: 15px 20px !important;
			border: none !important;
			color: $black !important;
			font-size: 14px !important;
			font-weight: $regular !important;
			margin-top: 5px;

			&::placeholder {
				font-style: normal !important;
				color: #4A4A4A !important;
				/* font-size: 14px !important; */
			}
		}

		input[type="submit"] {
			background-color: $white !important;
			font-size: 16px !important;
			font-weight: $semibold !important;
			transition: .2s;
			margin-top: 29px;

			&:hover {
				background-color: rgba(255,255,255,.9) !important;
			}
		}
	}

	@include below($s) {

		.top-text {
			padding-left: 0;
		}

		.form-field {
			width: 100% !important;
			margin-right: 0 !important;

			input[type="submit"] {
				margin-top: inherit;
			}
		}
	}
}

.employee_filter_hide {
	display: none;
}

.employee_filter_show {
	display: block;
}