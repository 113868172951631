.cookie-bar{
	position: fixed;
	bottom: 0;
	z-index: 2;
	width: 100%;
	color: #fff;

	a{
		color: inherit;
		text-decoration: underline;
	}

	.icon-close{
		position: absolute;
		right: 30px;
		top: 30px;

		img{
			width: 25px;
			height: auto;
		}
	}

	.title-m{
		font-weight: bold;
		margin-bottom: 5px;
	}

	.holder{
		position: relative;
		display: block;
		background: #2380F4;
		padding: 30px 50px 25px;
	}

}