.subpage-pagelist {
	background:$primaryBlue;
	padding:24px 30px;
	text-align:right;


	&.mobile-swipe-pagelist {
		text-align:left;
		height:40px;
		padding:0;
		display: block;
		clear:both;
		position: relative;
		overflow:hidden;
		overflow-x:scroll;
		-webkit-overflow-scrolling: touch;
		.pagelist-link {
			float:left;
			display: inline;
			margin:0 10px;
		}
	}

}
.subpage-holder {
	position: relative;
	&:before {
			content:'';
			background:linear-gradient(to right, rgba(20, 130, 250, 0.9) 0%, rgba(20, 130, 250, 0.5) 50%, rgba(20, 130, 250, 0.0) 100%);
			position: absolute;
			left:0;
			top:1px;
			bottom:1px;
			width:30px;
			z-index:99;
		}
		&:after {
			content:'';
			background:linear-gradient(to left, rgba(20, 130, 250, 0.9) 0%, rgba(20, 130, 250, 0.5) 50%, rgba(20, 130, 250, 0.0) 100%);
			position: absolute;
			right:0px;
			top:1px;
			bottom:1px;
			width:60px;
			z-index:99;
		}
}
.mobile-swipe-holder {
	width:5000px;
	padding:10px 20px;
	height:40px;
}
.pagelist-link {
	display: block;
	margin-bottom:12px;
	text-decoration: none;


	&:last-child  {
		margin-bottom:0;
	}
	&:hover {
		text-decoration: underline;
		opacity: 1 !important;
	}
}

.subpage-wp-menu {

	.is-main {
		text-transform: uppercase;
		a {
			font-size: 18px;
    		font-weight: 600;
		}
	}

	a {
		color: white;
		margin-bottom: 12px !important;
		font-weight: 500;
		display: block;

		&:hover {
			text-decoration: underline;
		}
	}


	.current-menu-item {
		> a {
			opacity: 0.5;
		}
		
	}

	&:hover {
		opacity: 1;
	}
	
}

.holder-flex-subpage {
	display: flex;

	@include below ($s) {
		flex-direction: column-reverse;
	}
}

.sub-sub-menu {
	li {
		list-style: none;
	}
}

.grandchildren-menu {
	li {
		list-style: none;
		width: 100%;

		a {
			background:$primaryBlue;
			color:white;
			font-weight:$medium;
			padding: 12px;
			position: relative;
			padding-right:80px;
			margin: 10px 0;
			position: relative;
			display: block;


			&:hover {
				text-decoration: underline;

				&:before {
					right: 9px;
				}
			}

			&:before {
				content: '';
				background-image: url('../img/icon-arrow-white.svg');
				position: absolute;
				height: 14px;
				width: 19px;
				right: 12px;
				background-size: cover;
				top: 50%; 
				transform: translateY(-50%);
				transition: all .2s ease-in-out;
			}
		}
	}
}