.footer {
	background:$primaryBlue;
	position: relative;
	z-index:3;
	max-width:100%;
	margin:0 auto;
	padding-bottom:36px;
	padding-top:36px;
	margin-top:24px;

	a {
		color:white;
	}

	.menu-footer-menu-container a {
		line-height: 36px;
		display: inline-block;
		font-size: 16px;
		font-weight: 600;
		transition: .1s;

		&:hover {
			text-decoration: underline;
		}
	}

	.hover-underline {
		line-height: 36px;
		display: inline-block;
	}

	.some {
		margin-top: 24px;

		li {
			position: relative;
			padding-left: 36px;
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.icon {
			position: absolute;
			left: 0;
			top: 2px;
		}

		a {
			display: block;
			line-height: 30px;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.office {
		width: 52%;
		display: inline-block;
		margin-bottom: 36px;
		vertical-align: top;

		&:nth-child(odd) {
			width: 46%;
		}
	}

	hr {
		border-color: #fff;
		opacity: .5;
		margin: 40px 0 12px;
	}

	.footer-disclaimer {
		p, a {
			line-height: 48px !important;
			display: inline-block;
		}
	}

	@include below($l) {

		.office {
			width: 100% !important;
		}
	}

	@include below($s) {

		.office:last-child {
			margin-bottom: 0;
		}

		.mbPrimary {
			margin-bottom: 12px;
		}

		.mbDouble {
			margin-bottom: 24px;
		}

		.logo img {
			width: 142px;
		}

		.col-4 {
			padding-bottom: 40px;
			margin-top: 20px;
			margin-bottom: 20px;

			&:after {
				content: '';
				display: block;
				position: absolute;
				width: calc(100% + 40px);
				border-bottom: 1px solid #89C0FC;
				bottom: 0px;
				margin-left: -20px !important;
				margin-right: -20px !important;
			}
		}

		.menu-footer-menu-container {
			position: relative;
			margin-bottom: 40px;
			padding-bottom: 40px;

			&:after {
				content: '';
				display: block;
				position: absolute;
				width: calc(100% + 40px);
				border-bottom: 1px solid #89C0FC;
				bottom: 0px;
				margin-left: -20px !important;
				margin-right: -20px !important;
			}
		}

		hr {
			display: none;
		}

		.footer-disclaimer {

			p, a {
				display: block;
				text-align: center;
				margin-right: 0;
			}
		}

		.menu-global-container {
			float: none;
			text-align: center;

			li:first-child {
				margin-left: 0;
			}
		}
	}
}

.to-top-icon {
	position: absolute;
	width: 40px;
	height: 40px;
	background: $white;
	margin-left: 48px;
	border-radius: 100%;
	border: 1px solid $primaryBlue;

	&:after {
		content: '';
		width: 20px;
		height: 16px;
		position: absolute;
		@include blue-arrow(32px);
		background-repeat: no-repeat;
		background-size: 20px auto;
		transform: rotate(-90deg);
		top: 11px;
		left: 10px;
	}

	@include below($xl) {
		top: -56px;
		right: 20px;
	}
}