.nav-search {
	display: inline-block;

	a {
		display: block;
	}

	&.active {

		.main-search {
			max-height: 500px;
			transition: .8s;
			box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);
		}

		> a .icon-search {
			background-image: url(../img/icon-close.svg);
		}
	}
}

.main-search {
	z-index: 100;
    max-height: 0;
    overflow: hidden;
    transition: .3s;
    text-align: left;
	/* width: 1440px; */
	width: 100%;
    padding: 0;
    position: absolute;
	display: block;
    /* left: 50%;
	transform: translateX(-50%); */
	left: 0;
	right: 0;

	.bg {
		background: $white;
		margin-top: 35px;
	}

	.bg-raster {
		opacity: .4;
	}

	.holder {
		padding-top: 40px;
    	padding-bottom: 40px;
	}

	form {
		position: relative;
		vertical-align: top;
		width: 1000px;
		max-width: 100%;
		left: 50%;
		transform: translateX(-50%);
	}

	.main-search-field {
		border: 0;
		width: calc(100% - 40px);
		background: transparent;
		/* color: $white; */
		font-size: 20px;
		text-align: center;

		&::-webkit-input-placeholder {
			/* color: $white; */
		}
	}

	&.mobile {
		max-height: initial;
		left: initial;
		transform: none;
		position: relative;
		width: 100%;
		margin-top: $gutter/2;

		input {
			background-color: #E5E5E5;
			padding: 10px 16px;
			width: 100%;
			color: #4A4A4A;
			font-size: 14px;
			border-radius: 4px;
			border: 2px solid #E5E5E4;

			&:focus {
				border-color: $primaryBlue;
				background-color: $white;
			}
		}

		.main-search-icon {
			background-image: url('../img/icon-search.svg');
			top: 13px;
    		right: 10px;
		}
	}
}


.main-search-icon {
	margin: 0 $gutter/2;
	position: relative;
	top: 4px;
}

/* .mobile-search {
	border: 2px solid transparent;
	border-radius: 4px;
	background-color:#E5E5E5;
	color:$black;
	width:100%;
	background-image:url('../img/icon-search-blue.png');
	background-repeat:no-repeat;
	background-position: top right;
	padding: 11px 20px 10px;

	&:focus {
		border: 2px solid $primaryBlue;
		background-color: $white;
	}

	&::placeholder {
	   color: #4A4A4A;
	}
} */