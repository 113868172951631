.featured-project {
	width:100%;
	display: block;
	margin-bottom:24px;
	position: relative;
	&:last-child {
		margin-bottom:0px;
	}
	&:hover {
		.top-slider-caption {
			background:rgba(20, 130, 250, 1);
		}
	}
}

.featured-project-image {
	width:100%;
	display: block;
}

.featured-project-caption {
	width:348px;
	max-width:100%;
	position: absolute;
	bottom:20px;
	right:20px;
	@include below($s) {
		position: relative;
		right:0;
		left:0;
		bottom:0;
		width:100%;
		background: $primaryBlue;
		a {
			display: block;
		}
	}
}