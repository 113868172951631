.blueprint-item {
	margin-bottom:12px;
	padding-bottom:12px;
	border-bottom:1px solid #ddd;
}

.blueprint-accordion {
	width: 100%;
	// border-top: 5px solid #000;

	&__title {
	    margin: 30px 0 10px 0;
	}

	&__item {
		width: 100%;
		padding: 20px 0 17px 0;
		border-top: 5px solid #000;
	}

	&__item-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
}

.blueprint-accordion__button {
	text-decoration: underline;
	cursor: pointer;
	opacity: 1;

	&:hover {
		opacity: 0.5;
	}
}

.blueprint-accordion__table {
	margin: 17px 0;
	background: #fff;

	&-header {
		padding: 17px 20px;
		background-color: $primaryBlue;
		@extend .blueprint-accordion__item-header;

		> * {
			color: #fff;
		}
	}

	&-content {
		border-left: 1px solid #979797;
		display: none;

		@include below(900px) {
			border-left: none;
		}
	}

	&-columns {
		display: flex;
		flex-direction: row;
		align-items: flex-start;

		@include below(900px) {
			flex-direction: column;
			margin-bottom: 20px;
			border-top: 1px solid gray;
			border-left: 1px solid gray;
		}

		&-titles {
			@include below(900px) {
				display: none;
			}
		}

		li {
			width: calc(100% / 6);
			max-width: calc(100% / 6);
			list-style: none;
			padding: 20px 5px;
			text-align: center;
			color: #000;
			border-bottom: 1px solid #979797;
        	border-right: 1px solid #979797;

			a {
				text-decoration: underline;
			}

			@include below(900px) {
				width: 100%;
				max-width: 100%;
				padding: 20px;
				display: flex;
				flex-direction: row;

				&:before {
					font-size: 16px;
					font-weight: 600;
					padding: 0 10px 0 0;
				}

				&:nth-of-type(1):before { content: "Lgh nr:"; }
				&:nth-of-type(2):before { content: "Storlek:"; }
				&:nth-of-type(3):before { content: "Boyta:"; }
				&:nth-of-type(4):before { content: "Faktablad:"; }
				&:nth-of-type(5):before { content: "Balong:"; }
				&:nth-of-type(6):before { content: "Hyra:"; }
			}
		}
	}
}

.js-accordion-content {
	display: none;

	&.show {
		display: block;
	}
}
