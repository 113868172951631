.stream-switch {
	z-index:9;
	position: absolute;
	top:10px;
	left:10px;
}

.switch-item {
	background:white;
	padding:3px 12px;
	margin-right:2px;
	font-weight:$medium;
	cursor:pointer;
	&.active {
		background:$primaryBlue;
	}
	&:hover {
		padding:5px 12px;
	}
}