.bg-raster {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 100%;
	width: 1144px;
	max-width: 100%;
	background-color: transparent;
	z-index: 0;
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
	pointer-events:none;
	&:before {
		content: '';
		position: absolute;
		width: 1px;
		height: 100%;
		left: 33.33333334%;
		border-left: 1px solid #ddd;
	}
	&:after {
		content: '';
		position: absolute;
		width: 1px;
		height: 100%;
		left: 66.66666667%;
		border-left: 1px solid #ddd;
	}
	&.dark {
		opacity:0.1;
		border-left:1px solid #000;
		border-right:1px solid #000;
		&:before {
			border-left:1px solid #000;
		}
		&:after {
			border-left:1px solid #000;
		}
	}
	@include below($m) {
		width:95%;
		&.dark {
			width:95%;
		}
	}
}