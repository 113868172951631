.article-standard {
	width:100%;
	position: relative;
	a {
		font-size:16px;
		line-height:24px;
		text-decoration: underline;
		color:$black;
		&:hover {
			opacity:0.5;
		}
	}
}

.article-main-media {
	width:100%;
	position: relative;
	margin: 0 0 48px;
	@include below($s) {
		margin:12px 0;
	}
	img, iframe, video {
		width:100%;
		height:auto;
	}
}

.embed-holder {
	position: relative;
	max-width: 100%;
	overflow: hidden;
	padding-top: 30px;
	padding-bottom: 56.25%;

	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}


//Dålig wysiwyg styling
.article-content {
	h2, h3, h4, p, blockquote, ul, ol {
		margin-bottom:6px;
	}
	h2, h3, h4 {
		font-weight:600;
	}
	img {
		max-width:100%;

		&.alignright {
			float:right;
			margin:24px;
		}
		&.alignleft {
			float:left;
			margin:24px;
		}
	}
	strong {
		font-weight:500;
	}
	ul {
		list-style: none;
		padding:0;
		margin:0;
	}

	li {
		padding-left: 1em;
		text-indent: -1em;
	}

	li:before {
		content: "•";
		font-size:22px;
		padding-right:8px;
		color: $primaryBlue;
	}
	hr {
		margin:24px 0;
	}
	iframe {
		min-height:400px;
	}
}
.blue-line-block, .black-line-block {
	margin:24px 0;
	display: block;
	width:100%;
	padding-top:24px;
	position: relative;
	&:before {
		background:$primaryBlue;
		height: 5px;
		position: absolute;
		top: 0;
		width: 100%;
		content: '';
	}
	h1, h2, h3 ,h4 {
		margin-bottom:6px;
		font-size:18px;
		font-weight:600;
		color:$primaryBlue;
	}
}
.black-line-block {
	h1, h2, h3 ,h4 {
		color:$black;
	}
	&:before {
		background:$black;
	}
}