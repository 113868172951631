.wysiwyg-wrapper {
    h1 {
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        padding-bottom: 12px;
    }

    h2 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        padding-bottom: 12px;
    }

    h3 {
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
        padding-bottom: 12px;
    }

    a {
       color: #1482FA;
       text-decoration: underline;
    }
    
    p {
        padding: 8px 0px;
    }
}