.contact-search-box {
	position: absolute;
	background:$primaryBlue;
	width:112%;
	padding-top:24px;
	display: none;
	z-index:99;
}
.js-open-contact-search {
	position: relative;
	&.active:before {
		content:'';
		background:$primaryBlue;
		width:100%;
		height:8px;
		position: absolute;
		top:-8px;
		left:0;
	}
}
.contact-search {
	width:88%;
	margin:0 auto;
	display: block;
}
.contact-results {
	padding:24px;
}
.contact-link {
	background:$black;
	padding:12px 24px;
}

.contact-list {
	width:100%;
	padding:0 0px;
}
.contact-item {
	list-style:none;
	color:white;
	padding:0;
	margin-bottom:24px;
	&:last-child {
		margin-bottom:0;
	}
}
.contact-image {
	height:62px;
	width:62px;
	object-fit:cover;
	display: inline-block;
	margin-right:15px;
}
.contact-item-copy {
	display: inline-block;
	vertical-align:top;
	max-width:250px;
	a:first-of-type {
		margin-right:5px;
	}
	p, a {
		line-height:14px;
	}
	p:nth-child(2n) {
		margin-bottom:-4px;
	}
}