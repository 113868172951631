.search-item {
	display: block;
	margin:24px 0;
	color:$black;
	padding-right:15%;
	position: relative;
	&:first-child {
		margin-top:0;
	}
	&:after {
		content:'';
		@include blue-arrow(32px);
		background-position: center;
		position: absolute;
		width:68px;
		height:54px;
		padding:10px 20px;
		right:0px;
		top:50%;
		transform:translateY(-50%);
		transition: .2s;
	}
	&:hover:after {
		right: -20px;
	}

}