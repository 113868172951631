.grid-layout {
	display:grid;
	grid-template-columns: repeat(3, 30.9%);
	grid-auto-rows:207px;
	grid-gap: 20px 40px;

	@include below($m) {
		grid-gap:12.5px 25px;
		grid-auto-rows: 207px;
	}
	@include below($s) {
		display:block;
	}
}
.ie .grid-layout, .safariold .grid-layout {
	display:flex !important;
	flex-wrap:wrap;
}
.blue-box {
	background:$primaryBlue;
	position:relative;
	cursor:pointer;
	background-size:cover;
	background-position:center;
	.overlay {
		position:absolute;
		width:100%;
		height:100%;
		background:$primaryBlue;
		opacity:0.7;
		z-index:0;
		top:0;
		left:0;
	}
	&.onebyone {
		grid-row: span 1;
		grid-column: span 1;
	}
	&.onebytwo {
		grid-row: span 2;
		grid-column: span 1;
		.blue-box-text {
			font-size:21px;
			line-height:29px;
			width:95%;
			&.with-image {
				width:100%;
				padding:0 12px;
				text-align:left;
			}
			@include below($m) {
				font-size:18px;
				line-height:26px;
			}
		}
	}
	&.twobyone {
		grid-row: span 1;
		grid-column: span 2;
		.blue-box-text {
			font-size:21px;
			line-height:29px;
			width:90%;
			&.quote {
				margin:8% auto;
			}
			@include below($m) {
				font-size:18px;
				line-height:26px;
				width:90%;
			}
		}
	}
	&.twobytwo {
		grid-row: span 2;
		grid-column: span 2;
		.blue-box-text {
			font-size:21px;
			line-height:29px;
			width:90%;
		}
	}
	@include below(400px) {
		min-height:207px;
	}
	@include below($s) {
		min-height:227px;
		margin-bottom:12px;
		padding-bottom:12px;
		padding-top:12px;
		.blue-box-text {
			font-size:14px !important;
			line-height:20px !important;
			&.with-image {
				position: static;
			}
		}
		&:before {
			display:none !important;
		}
	}
	video {
		position: absolute;
		top:50%;
		left:50%;
		transform:translate(-50%, -50%);
		width:90%;
	}
}

.blue-box-number {
	font-size:16px;
	color:white;
	position: absolute;
	top:6px;
	left:6px;
	z-index:3;
}
.blue-box-image {
	max-width:70%;
	position: absolute;
	top:50%;
	left: 50%;
	transform:translate(-50%, -50%);
	&.with-text {
		max-width:60%;
		top:35%;
	}
	@include below($s) {
		position: static;
		top:initial;
		left:initial;
		transform:translate(0%, 0%);
		width:100%;
		max-width:100%;
		margin:20px auto;
		display: block;
		&.with-text {
			width:60%;
			max-width:60%;
		}
	}
}
.blue-box-text {
	font-size:16px;
	position: absolute;
	bottom:12px;
	left:12px;
	width:80%;
	color:white;
	&.with-image {
		left:0;
		width:100%;
		text-align:center;
	}
	p {
		margin-bottom:12px;
		&:last-child {
			margin-bottom:0px;
		}
	}
	@include below($m) {
		font-size:14px;
		line-height:20px;
	}
	&.quote {
		position:static;
		vertical-align: middle;
		width:90%;
		margin:13% auto;
		text-align:center;
		@include below($s) {
			margin:0 auto;
			padding:25% 3%;
			width:100%;
		}
		blockquote {
			margin:0;
			p {
				font-size:16px;
				text-transform: none;
				color:white;
				font-weight:normal;
				font-style:italic;
				margin:0;
			}
		}
		p {
			font-size:10px;
			color:$black;
			text-transform: uppercase;
			font-weight:bold;
			margin-top:10px;
		}
	}
}
.ie .blue-box, .safariold .blue-box {
	@include grid(4);
	float:left;
	min-height:207px;
	grid-row:0 !important;
	grid-column: 0 !important;
	margin-bottom:20px;
	padding:12px;
	.blue-box-text {
		font-size:16px !important;
		line-height:22px !important;
	}
	@include below($s) {
		@include grid(12);
	}
	.blue-box-image {
		position: static;
		transform:translate(0%, 0%);
	}
	.blue-box-text {
		position:static;
		margin-top:20px;
	}
}
.pros-title {
	margin:70px 0 110px 0;
	color: #1482FA;

	h1 {
		font-size: 38px;
		line-height:42px;
		letter-spacing: 12px;
		text-transform:uppercase;
		text-align: center;
		font-weight:600;
		@include below($s) {
			font-size:30px;
			line-height:34px;
			letter-spacing:10px;
		}
	}
	p {
		color:$black;
		text-align:center;
		max-width:650px;
		margin:24px auto 0 auto;
	}
}

.stroke-row-holder{
	display: block;
	position: relative;



}

.stroke-row-item{
	height:5px;
	background:$black;
	margin-bottom: $gutter / 2;

	@include below($s){
		display: none;

		&:first-child{
			display: block;
		}
	}
}