.icon-search {
	width:20px;
	height:20px;
	display: inline-block;
	background-image:url('../img/icon-search.svg');
	background-repeat: no-repeat;
	background-size:contain;
	cursor: pointer;

	.main-search & {
		right: 0;
    	position: absolute;
	}
}

.icon {
	width:20px;
	height:20px;
	display: block;
	background-repeat: no-repeat;
	background-size:cover;

	&.icon-facebook {
		background-image:url('../img/icon-facebook.svg');
	}

	&.icon-linkedin {
		height:18px;
		background-image:url('../img/icon-linkedin.svg');
	}

	&.icon-instagram {
		background-image:url('../img/icon-instagram.svg');
	}
}

.icon-play-small {
	width:20px;
	height:20px;
	display: inline-block;
	background-image:url('../img/icon-play-small.png');
	background-repeat: no-repeat;
	background-size:cover;
	position: relative;
	margin-right:10px;
	top:3px;
}

.icon-blue-arrow-small {
	width:15px;
	height:12px;
	display: inline-block;
	background-image:url('../img/icon-arrow-blue.png');
	background-repeat: no-repeat;
	background-size:cover;
	position: relative;
	margin-left:10px;
	top:0px;
}