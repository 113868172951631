$cm: 60px;

body{
}

.display{
	background: $primaryBlue;
	font-weight: bold;
	color: $primaryBlue;
	text-align: center;

	span{
		display: inline-block;
		background: #fff;
	}

	.small{
		font-size: 1.3 * $cm;
	}

	.medium{
		font-size: 4.0 * $cm;
		font-weight: 600;
	}

	.large{
		font-size: 4.5 * $cm;
	}

}


.display-1{

	.group-1{
		position: absolute;
		top: 190px;
		left: 410px;
		display: block;
		text-align: center;
	}
	.small{
		width: 330px;
		left: 0;
		display: block;
		position: absolute;
	}
	.paint{
		top: -15px;
	}
	.screw{
		top: 215px;
	}
	.wood{
		top: 450px;
	}
	.total{
		top: 700px;
		left: -355px;
	}
}
.display-1 .display{
	background: #fff !important;
}
.display-1 .group-2{
	.large{
		position: absolute;
		width: 450px;
	}
	.apartments{
		top: 1395px;
		left: 10px;
		font-size: 230px;
	}
	.modules{
		top: 1420px;
		left: 585px;
	}
}
.display-2{
	.homes{
		position: absolute;
		left: 330px;
		width: 1280px;
		top: 315px;
	}
}

.display-3{
	background: $primaryBlue;
	.circle{
		background: none;
		display: inline-block;
		border: #fff solid $cm * 0.15;
		border: none;
		width: 400px;
		height: 400px;
		border-radius: 500px;
		position: absolute;
		left: 255px;
		top: 345px;

		> span{
			background: none;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			font-size: 140px;
		}
	}

	.women{
		color: #fff;
		left: 397px;
	}
	.men{
		color: #000;
		border-color: #000;
		left: 990px;
	}
}

.display-4{
	background: #fff;
	.dispsplay{
	}

	.small{
		position: absolute;
		left: 70px;;
		width: 925px;
	}
	.power{
		top: 1353px;
	}
	.power-total{
		top: 1630px;
	}
}

.display-5{
	position: relative;
	background: $primaryBlue;

	.slider{
		z-index: 1;
		position: absolute;
		width: 845px;
		top: 830px;
		left: 83px;
	}

	.main-image{
		width: 100%;
	}


	.meta-holder{
		position: absolute;
		background: $primaryBlue;
		left: 33px;
		width: 405px;
		height: 12 *  $cm;
		padding: 80px 40px 40px;
		text-align: center;
		top: calc(100% - 60px);

		&:after{
			content: '';
			position: absolute;
			display: block;
			top: -40px;
			left: calc(50% - 15px);
			width: 60px;
			height: 90px;
			background-image: url(../img/nail.png);
			background-size: cover;
		}

		h3{
			display: block;
			font-size: 35px;
			line-height: 40px;
			color: #fff;
			font-weight: bold;
			letter-spacing: 5px;
			margin-bottom: 40px;
		}
		p{
			margin: 20px 0;
			font-size: 24px;
			color: #fff;
			line-height: 30px;
		}
	}

	.map{
		position: absolute;
		z-index: 2;
		background-image: url(../img/map.svg);
		background-repeat: no-repeat;
		background-size: 100% auto;
		width: 1440px;
		height: 78.9 * $cm;
		left: 470px;
		top: 410px;
	}

	.nail{
		text-indent: -9999px;
		position: absolute;
		width: 100px;
		height: 200px;
		background: url(../img/nail-1.png) no-repeat;
		background-size: 50% auto;

	}

	.key-0,
	.key-4
	.key-8 {
		background: url(../img/nail-1.png) no-repeat;
	    transform: rotate(-0deg);
	}

	.key-1,
	.key-5{
		background: url(../img/nail-2.png) no-repeat;
		transform: rotate(5deg);
	}

	.key-2,
	.key-6 {
		background: url(../img/nail-3.png) no-repeat;
	    transform: rotate(-10deg);
	}

	.key-3
	.key-7 {
		background: url(../img/nail-4.png) no-repeat;
		transform: rotate(10deg);
	}
}

.display-6{
	video{
		width: 1800px;
		height: 1012px;
	}
}