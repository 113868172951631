.form-wrap {
	margin:20px 0;
	display: block;
}

.signup-form {
	width:100%;
	display: block;
	position: relative;
}

.input-wrap, .form-field {
	display: block;
	margin-bottom:10px;
	position: relative;
}
.form-field {
	@include grid(6);
	margin-right:40px !important;
	&:nth-child(odd) {
		margin-right:0 !important;
	}
	&.no--gutter {
		@include grid(6, $gutter:0px);
	}
	@include below($m) {
		@include grid(6, $gutter / 2);
	}
	float: left !important;
	position:relative !important;
	vertical-align: top !important;

	@include below($s) {
		width:100%;
		margin:12.5px 0 !important;
	}
}
#frm_form_15_container .form-field {
	margin-right:40px !important;
	&:nth-child(even) {
		margin-right:0 !important;
	}
}
.input-label , .frm_primary_label {
	font-size:12px !important;
	font-weight:$semibold !important;
	display: block !important;
	margin-bottom: 0px !important;
	color:$black !important;
}
.text-input, .dropdown, .form-field input, .form-field select, .form-field textarea {
	display: block !important;
	border:0 !important;
	box-shadow:none !important;
	padding-left:0 !important;
	border-bottom:2px solid $black !important;
	width:100% !important;
	font-weight:$medium !important;
	font-size:16px !important;
	padding-bottom:5px !important;
	position: relative !important;
	background:none !important;
	background-image:none !important;
	border-radius:0px !important;
	color:$black !important;
	&::-webkit-input-placeholder {
	   color: #899397 !important;
	   font-size:16px !important;
	   font-weight:400 !important;
	   font-style:italic !important;
	}
	&:-moz-placeholder { /* Firefox 18- */
	   color: #899397 !important;
	   font-size:16px !important;
	   font-weight:400 !important;
	   font-style:italic !important;
	}

	&::-moz-placeholder {  /* Firefox 19+ */
	  color: #899397 !important;
	   font-size:16px !important;
	   font-weight:400 !important;
	   font-style:italic !important;
	}

	&:-ms-input-placeholder {
	   color: #899397 !important;
	   font-size:16px !important;
	   font-weight:400 !important;
	   font-style:italic !important;
	}
	&:focus {
		border-color:$primaryBlue !important;
	}
}
.frm_style_formidable-style.with_frm_style input[type=submit] {
	// font-size:18px !important;
	// font-family:'Graphik Web', sans-serif !important;
}
.frm_style_formidable-style.with_frm_style label.frm_primary_label, .frm_style_formidable-style.with_frm_style.frm_login_form label {
	font-family:'Graphik Web', sans-serif !important;
}
.frm_radio {
	display: inline-block !important;
	margin-right:10px !important;
	input {
		width:auto !important;
	}
}
.text-area, .form-field textarea {
	height:120px;
}
.dropdown, .form-field select {
	appearance: none !important;
	background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") !important;
	background-position: 100% 50% !important;
	background-repeat: no-repeat !important;
}
.frm_error_style {
	background:$primaryBlue !important;
	border:0 !important;
	border-radius:0 !important;
	color:white !important;
	font-weight:$medium !important;
	padding:5px 10px !important;
}
label.frm_primary_label.focus {
	color:$primaryBlue !important;
}
.frm_button_submit,
.form-button, .frm_submit input {
	appearance:none !important;
	font-weight:$medium !important;
	display:inline-block !important;
	margin-top:10px !important;
	color:$black !important;
	border:0 !important;
	text-align:left !important;
	box-shadow:none !important;
	padding:0 !important;
	text-decoration: underline !important;
	font-size:18px !important;
	font-family:'Graphik Web', sans-serif !important;
	&:hover {
		opacity:0.5 !important;
		background: 0 !important;
	}
}
.with_frm_style .frm_form_field {
	clear:none !important;
}
.text-input:focus ~ .input-label, .dropdown:focus ~ .input-label {
	color:$primaryBlue;
}

.frm_form_fields .form-errors {
	padding-top: 20px;

	p {
		color: red;
	}
}