.single-suntboende, .post-type-archive-suntboende {

	.header {
		text-align: center;

		h1 {
			font-size: 128px;
			line-height: 128px;

			@include below($s) {
				font-size: 50px;
				line-height: 50px;
			}
		}

		.image-holder {
		    width: 100%;
		    height: 270px;
		    background-size: cover;
		    background-position: center center;
		    margin-top: -55px;

		    @include below($s) {
		    	height: 170px;
		    	margin-top: -23px;
		    }
		}
	}

	.suntboende-preview {

		h3 {
			font-family: 'Dala Floda', 'serif';
			font-size: 24px;
			line-height: 24px;
		}

		.image-holder {
			width: 100%;
			height: 200px;
		    background-size: cover;
		    background-position: center center;
		}

		a {
			font-weight: $semibold;
		}
	}

	.header-image img {
		max-width: 100%;
		height: auto;
	}

	.sb-holder {
		width: 760px;
		max-width: 100%;
		margin: 0 auto;
		text-align: left;
		
		&.wider {
			width: 830px;
		}

		&.center {
			text-align: center;
		}

		@include below($s) {
			
			&.center {
				text-align: left;
			}
		}
	}

	.category-title {
		color: $gray;
		font-weight: 500;
		font-size: 18px;
		text-transform: uppercase;
	}

	.info {
		font-size: 14px;
		font-style: italic;
	}

	.center {
		text-align: center;
	}

	h1 {
		font-family: 'Dala Floda', 'serif';
		font-size: 72px;
		line-height: 72px;

		@include below($s) {
			font-size: 40px;
			line-height: 50px;
		}
	}

	.two-cols {


		.col {
			width: 48%;

			@include below($m) {
				width: 100%;
				margin-left: 0 !important;
				margin-right: 0 !important;
				margin-bottom: 24px;
			}
		}

		&.info_box {
	
			.col {
				margin-left: 2%;
				float: left;

				&:first-child {
					margin-right: 2%;
					margin-left: 0;
				}

				&.info_box {
					background: $primarySuntBoende;
					padding: 30px;

					p {
						margin-bottom: 12px;
						color: $white;
						font-size: 16px;
						line-height: 18px;

						&:last-child {
							margin-bottom: 0;
						}
					}

					@include below($m) {
						width: auto;
						max-width: 100%;
						margin-left: 0 !important;
						margin-right: 0 !important;
					}

					@include below($s) {
						width: 100%;
					}
				}
			}
		}

		&.info_box.text-right {

			.col {
				margin-right: 2%;
				margin-left: 0;

				&:first-child {
					float: right;
					margin-left: 2%;
					margin-right: 0;
				}
			}
		}

		&.image {
			display: flex;

			.col {
				margin-right: 2%;
				margin-left: 0;
			}

			.col.image {
				background-size: cover;
				background-position: center center;
				margin-left: 2%;
				margin-right: 0;
				min-height: 250px;
			}

			@include below($m) {
				display: block;

				.col.image {
					height: 250px;
				}
			}
		}

		&.image.text-right {

			.col {
				margin-left: 2%;
				margin-right: 0;
				order: 2;
			}

			.col.image {
				margin-right: 2%;
				margin-left: 0;
				order: 1;
			}
		}
	}

	.three-cols {

		.col {

			@include below($s) {
				width: 100% !important;
				margin-right: 0;
			}
		}

		.image-holder {
			width: 100%;
		    height: 360px;
		    background-size: cover;
		    background-position: center center;

		    @include below($l) {
		    	height: 250px;
		    }

		    @include below($s) {
				width: 40%;
				height: 300px;
				float: left;
				margin-right: 24px;
		    }

		    @include below($s) {
				height: 250px;
		    }

		    @include below($xs) {
		    	width: 100%;
		    	display: block;
		    	float: none;
				height: 280px;
		    }
		}

		img {
			width: 100%;
			max-width: 100%;
		}
	}

	.quote {
		font-size: 48px;
		line-height: 58px;
		font-family: 'Dala Floda', 'serif';
		text-align: center;

		@include below($m) {
			font-size: 40px;
			line-height: 50px;
		}

		@include below($s) {
			font-size: 30px;
			line-height: 40px;
		}
	}

	img.fullwidth {
		width: 100%;
		height: auto;
	}

	.wysi {

		ul {
			padding-left: 18px;
		}

		ol {
			padding-left: 20px;
		}

		blockquote {
			font-size: 25px;
			line-height: 30px;
			font-family: 'Dala Floda', sans-serif;
		}
	}

	.next-article {
		background-color: $primarySuntBoendeLighter;
		display: flex;
		background-position: relative;

		.image-left {
			width: 250px;
			max-width: 40%;
			background-size: cover;
			background-position: center center;	

			@include below($s) {
				display: none;
			}	
		}

		.right {
			padding: 30px;

			@include below($s) {
				padding: 20px;
			}

			a {
				text-decoration: none;
			}

			h1 {
				font-size: 52px;
				line-height: 52px;
				color: $primarySuntBoende;

				@include below($s) {
					font-size: 30px;
					line-height: 30px;
				}

				i {
					font-style: normal;
				}
			}
		}
	}
}
.longreading {

	.header {
		text-align: center;

		h1 {
			font-size: 128px;
			line-height: 128px;

			@include below($s) {
				font-size: 50px;
				line-height: 50px;
			}
		}

		.image-holder {
		    width: 100%;
		    height: 270px;
		    background-size: cover;
		    background-position: center center;
		    margin-top: -55px;

		    @include below($s) {
		    	height: 170px;
		    	margin-top: -23px;
		    }
		}
	}

	.suntboende-preview {

		h3 {
			font-size: 24px;
			line-height: 24px;
		}

		.image-holder {
			width: 100%;
			height: 200px;
		    background-size: cover;
		    background-position: center center;
		}

		a {
			font-weight: $semibold;
		}
	}

	.header-image img {
		max-width: 100%;
		height: auto;
	}

	.sb-holder {
		width: 760px;
		max-width: 100%;
		margin: 0 auto;
		text-align: left;
		
		&.wider {
			width: 830px;
		}

		&.center {
			text-align: center;
		}

		@include below($s) {
			
			&.center {
				text-align: left;
			}
		}
	}

	.category-title {
		color: $gray;
		font-weight: 500;
		font-size: 18px;
		text-transform: uppercase;
	}

	.info {
		font-size: 14px;
		font-style: italic;
	}

	.center {
		text-align: center;
	}

	.longreading-title, .longreading-title p {
		font-size: 72px;
		line-height: 72px;
		font-weight:500 !important;
		@include below($s) {
			font-size: 40px;
			line-height: 50px;
		}
	}

	.two-cols {


		.col {
			width: 48%;

			@include below($m) {
				width: 100%;
				margin-left: 0 !important;
				margin-right: 0 !important;
				margin-bottom: 24px;
			}
		}

		&.info_box {
	
			.col {
				margin-left: 2%;
				float: left;

				&:first-child {
					margin-right: 2%;
					margin-left: 0;
				}

				&.info_box {
					background: $primaryBlue;
					padding: 30px;

					p {
						margin-bottom: 12px;
						color: $white;
						font-size: 16px;
						line-height: 18px;

						&:last-child {
							margin-bottom: 0;
						}
					}

					@include below($m) {
						width: auto;
						max-width: 100%;
						margin-left: 0 !important;
						margin-right: 0 !important;
					}

					@include below($s) {
						width: 100%;
					}
				}
			}
		}

		&.info_box.text-right {

			.col {
				margin-right: 2%;
				margin-left: 0;

				&:first-child {
					float: right;
					margin-left: 2%;
					margin-right: 0;
				}
			}
		}

		&.image {
			display: flex;

			.col {
				margin-right: 2%;
				margin-left: 0;
			}

			.col.image {
				background-size: cover;
				background-position: center center;
				margin-left: 2%;
				margin-right: 0;
				min-height: 250px;
			}

			@include below($m) {
				display: block;

				.col.image {
					height: 250px;
				}
			}
		}

		&.image.text-right {

			.col {
				margin-left: 2%;
				margin-right: 0;
				order: 2;
			}

			.col.image {
				margin-right: 2%;
				margin-left: 0;
				order: 1;
			}
		}
	}

	.three-cols {

		.col {

			@include below($s) {
				width: 100% !important;
				margin-right: 0;
			}
		}

		.image-holder {
			width: 100%;
		    height: 360px;
		    background-size: cover;
		    background-position: center center;

		    @include below($l) {
		    	height: 250px;
		    }

		    @include below($s) {
				width: 40%;
				height: 300px;
				float: left;
				margin-right: 24px;
		    }

		    @include below($s) {
				height: 250px;
		    }

		    @include below($xs) {
		    	width: 100%;
		    	display: block;
		    	float: none;
				height: 280px;
		    }
		}

		img {
			width: 100%;
			max-width: 100%;
		}

		.no-image {
			margin-top: 30px;
			
			&:before {
			    content: '';
			    width: 100%;
			    height: 5px;
			    background: #1482fa;
			    position: absolute;
			    top: 0;
			}

			.copy {
				margin-top: 30px;
			}
		}
	}

	.quote {
		font-size: 48px;
		line-height: 58px;
		text-align: center;

		@include below($m) {
			font-size: 40px;
			line-height: 50px;
		}

		@include below($s) {
			font-size: 30px;
			line-height: 40px;
		}
	}

	img.fullwidth {
		width: 100%;
		height: auto;
	}

	.wysi {

		ul {
			padding-left: 18px;
		}

		ol {
			padding-left: 20px;
		}

		blockquote {
			font-size: 25px;
			line-height: 30px;
		}
	}
}