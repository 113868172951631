.process, .living {
	margin:24px 0;
	width:100%;
	@include below($s) {
		margin:5px 0;
	}
}
.process-logotype, .living-logotype {
	width:100%;
	text-align:center;
	position: absolute;
	top:50%;
	transform:translateY(-50%);
	img {
		width:204px;
		height:auto;
	}
	@include below($s) {
		position: relative;
		top:auto;
		transform:translateY(0%);
	}
}
.process-copy, .living-copy {
	max-width:550px;
	@include below($m) {
		max-width:100%;
	}
}
.page-template-tpl-start .holder .process:first-child .col-8 {
	margin-right: 39px;
}