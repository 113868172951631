.filter-wrap {
	width:100%;
	position: relative;
}
.filter {
	width:100%;
	padding:24px 0;
	background:$primaryBlue;
	position: relative;
	display: none;
	&.visible {
		display: inline-block;
	}
	&:before, &:after {
		content:'';
		width:20px;
		background:$primaryBlue;
		height:100%;
		position:absolute;
		top:0;
		bottom:0;
		display: block;
		visibility: visible;
	}
	&:before {
		left:-20px;
	}
	&:after {
		right:-20px;
	}
}
.filter-set {
	border:0;
	margin:0;
	padding:0;
}
.filter-check {
	border-radius:0;
	margin-right:6px;

}
.filter-label {
	font-size:16px;
	font-weight:$medium;
	color:white;
}
.input-wrap.small {
	display: block;
	margin:3px 0;
	padding:0;
	&.inactive {
		opacity:0.2;
		pointer-events:none;
	}
}
ul.list {
	list-style:none;
}