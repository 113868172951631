.four-oh-four {
	height:calc(100vh - 231px);
	max-height:800px;
	background-size:cover;
	margin-bottom:-20px;
	margin-top:24px;
}

.four-oh-content {
	padding-top:48px;
}

.four-oh-search {
	width:351px;
	max-width:100%;
	&::-webkit-input-placeholder {
	   color: #899397;
	   font-size:18px;
	   font-weight:500;
	   font-style:italic;
	}
	&:-moz-placeholder { /* Firefox 18- */
	   color: #899397;
	   font-size:18px;
	   font-weight:500;
	   font-style:italic;
	}

	&::-moz-placeholder {  /* Firefox 19+ */
	  color: #899397;
	   font-size:18px;
	   font-weight:500;  
	   font-style:italic;
	}

	&:-ms-input-placeholder {  
	   color: #899397;
	   font-size:18px;
	   font-weight:500; 
	   font-style:italic;
	}
}