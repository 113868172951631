.step-title {
	margin-bottom: 20px;
	font-weight: bold;
}

.survey-checkbox-wrap {
	display: block;
	margin-bottom: 10px;
	cursor: pointer;
	input {
		margin: 10px 5px;
	}
}

.draggable-item {
	position: relative;
	display: block;
	padding: 10px 15px;
	cursor: move;
	border: 1px solid $black;
	margin-bottom: 15px;
	background: $white;
}

.ghost {
	opacity: 0.5;
	background: #c8ebfb;
}

.flip-list-move {
	transition: transform 0.5s;
}

.no-move {
	transition: transform 0s;
}

.form-error-message {
	color: red;
}

.frm_submit.tentant-survey {
	margin-left: 5px;
	margin-top: 10px;
	input {
		background: #FFF;
	}
}