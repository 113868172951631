.linkblock {
	position: relative;
	display: block;
	text-decoration: none;

	&.blue {
		padding-top:24px;

		&:before {
			@include pre-line($primaryBlue, 5px);
		}

		&.with-arrow {

			h4 {
				padding-right: 80px;
			}

			p {
				padding-right: 80px;
				position: relative;

				&:after {
					content: '';
					width: 32px;
					height: 25px;
					position: absolute;
					@include blue-arrow(32px);
					right: 20px;
					top: 0;
					transition: .2s;
				}
			}

			&:hover {

				p:after {
					right: 0;
				}
			}
		}
	}

	&.black {
		padding-top:24px;
		&:before {
			@include pre-line($black, 5px);
		}
	}
}

.linkblock-link {
	color:$black;
	text-decoration: underline;
	margin-top:12px;
	max-width:80%;
	position: relative;
	font-weight:$medium;
	&.with-arrow {
		&:after {
			content:'';
			@include blue-arrow(32px);
			background-position: center;
			position: absolute;
			width:68px;
			height:54px;
			padding:10px 20px;
			right:-25%;
			top:50%;
			transform:translateY(-50%);
		}
		&:hover:after {
			@include white-arrow(32px);
			background-color:$primaryBlue;
		}
	}
}