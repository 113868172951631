.faq-item {
	display: block;
	margin:1px auto;
	cursor:pointer;
	pointer-events: none;
}

.faq-item-heading {
	background:$primaryBlue;
	pointer-events: all;
	color:white;
	font-weight:$medium;
	padding: 12px;
	position: relative;
	padding-right:80px;
	margin: 10px 0;
	h2 {
		font-size: 16px;
    	line-height: 24px;
		font-weight:600;
	}
	.circle {
		border: 2px solid $white;
		border-radius: 100%;
		width: 24px;
		height: 24px;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);

		> span {

			&:before,
			&:after {
				content: '';
				position: absolute;
				width: 12px;
				height: 2px;
				top: 9px;
				left: 4px;
				background-color: $white;
			}

			&:after {
				transform: rotate(90deg);
			}
		}
	}
}

.faq-item.open {
	.circle span:after {
		display: none;
	}
	.faq-item-content {
		display: block;
	}
}

.faq-item-content {
	display: none;
	padding:12px;
	pointer-events: all;
}
