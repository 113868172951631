.calculator-holder {
	max-width:1440px;
	width:100%;
	margin:0 auto;
}

  .co2-title {
	font-size:165px;
	line-height:1.5;

	sub {
		font-size:110px;
	}

	@include below($s) {
		font-size:55px;

		sub {
			font-size:36px;
		}
	}
  }

.bta-label,
.floors-label {
	font-size:32px;
	line-height:32px;
}

.calculate-button {
	font-size:24px;
	line-height:1.3;
	transition:opacity .2s;
}

.calculator-button:hover {
	opacity:.9;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0;
	/* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
	-moz-appearance: textfield;
	/* Firefox */
}