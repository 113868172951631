.video-header {
    position:relative;

	.video-caption {
	position: absolute;
	left: 5%;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);

		h1 {
			font-size:60px;
			line-height:72px;
			color:white;
			font-weight:600;

			span {
				color:#000000;
			}
		}


		p {
			color: $white;
			font-size: 24px;
			line-height: 36px;
			margin-top: 24px;
			width: 580px;
			max-width: 100%;
		}

		@include below($l) {

			h1 {
				font-size:40px;
				line-height:52px;
			}

			p {
				font-size: 20px;
				line-height: 30px;
				margin-top: 10px;
				width: 55%;
			}
		}

		@include below(950px) {

			p {
				width: 450px;
			}
		}
	}
}

.mobile-header {
	background-color: #426278;

	.text-holder {
		padding: 150px 0;
		text-align: center;
	}

	h1 {
		font-size:24px;
		line-height:34px;

		span {
			color: #000;
		}
	}

	p {
		font-size: 18px;
		line-height: 24px;
		width: 500px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}