/* purgecss start ignore */

.flickity-button.next{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMiI+PHBhdGggZD0iTTE4LjYyNSAxLjI3bDExLjIzIDExLjIzLTExLjIzIDExLjIzTTAgMTIuNWgyOS44NTUiLz48L2c+PC9nPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-position: right;
    height: 500px;
    right: 20px;
    left: auto;
}

.flickity-button.previous{
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMiI+PHBhdGggZD0iTTE4LjYyNSAxLjI3bDExLjIzIDExLjIzLTExLjIzIDExLjIzTTAgMTIuNWgyOS44NTUiLz48L2c+PC9nPjwvc3ZnPg==");
     background-repeat: no-repeat;
     transform: rotate(180deg);
     background-position: right;
     height: 500px;
     left: 20px;
     left: auto;
}




.swiper-slide-button{
  position: absolute;
    top: 50%;
    width: 50px;
    height: 25px;
    margin-top: -22px;
    pointer-events: none;
    cursor: pointer;

    background-position: center;
    background-repeat: no-repeat;
   }





   .js-slider {
    padding-bottom: 3rem;
    min-height: 800px;
    height:100%;
    position: relative;
    overflow-y: visible;
    @include below ($m) {
        min-height: 600px;
    }
    @include below ($s) {
        min-height: 500px;
    }
    &:focus {
        outline: 0;
    }
}
.flickity-slider {
    min-height: 800px;
    height:100%;
    width: 100%; 
    @include below ($m) {
        min-height: 600px;
    }
    @include below ($s) {
        min-height: 500px;
    }
}
.slide-background {
    background-size: cover;
    min-height: 800px;
    height:100%;
    width: 100%;
    @include below ($m) {
        min-height: 600px;
    }
    @include below ($s) {
        min-height: 500px;
    }
}
.slider-holder {
    min-height: 800px;
    height:100%;
    width: 100%; 
    background-size: cover;
    background-position: center center;
    opacity: 0.5;
    cursor: grab;
    @include below ($m) {
        width: 90%;
        min-height: 600px;
    }
    @include below ($s) {
        min-height: 500px;
        width: 100%;
    }

    &.is-selected{
        opacity: 1;
    }
}
//temporary solution for showing people in startpage slider

.slider-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: -2.5rem;
  
}

.flickity-button-icon{
    opacity: 0;
}
.flickity-button {
    &.previous {
      position: absolute;
        top: 0;
        left: 10px;
        width: 20%;
        height: 100%;
        // opacity: 0;
        @include below ($m) {
            width: 10%; 
        }
        @include below ($s) {
            display: none;
        }
    }
    &.next {
        position: absolute;
        top: 0;
        right: 10px;
        width: 20%;
        height: 100%;
        // opacity: 0;
        @include below ($m) {
            width: 10%; 
        }
        @include below ($s) {
            display: none;
        }
    }
}
.flickity-page-dots {
    counter-reset: a;
    list-style: none;
    padding-left: 0;
    position: absolute;
    bottom: 30px;
    margin: 0;
    left: 50%; 
    transform: translateX(-50%); 
}
.dot {
    display: inline-block;
    margin-right: 10px; 
    cursor: pointer; 
    height: 12px;
    width: 12px; 
    border: 1px solid $white; 
    border-radius: 50%;
    &:last-of-type {
        margin-right: 0;
    }
    &.is-selected {
        background-color: $white;
    }
}
.flex-slide-column {
    width: 50%;
    background-size: cover;
    min-height: 800px;
    position: relative;
    background-position: center center;
    @include below ($m) {
        min-height: 600px;
    }
    @include below ($s) {
        min-height: 500px;
    }
}
/* purgecss end ignore */