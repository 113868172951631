// Font weights.
$thin: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

//Graphik
/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2013 Schwartzco Inc.
        License: 1603-FJBWJG
*/
@font-face {
	font-family: 'Graphik Web';
	src: url('../font/Graphik-Semibold-Web.eot');
	src: url('../font/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
	url('../font/Graphik-Semibold-Web.woff') format('woff');
	font-weight:  600;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Graphik Web';
	src: url('../font/Graphik-Medium-Web.eot');
	src: url('../font/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
	url('../font/Graphik-Medium-Web.woff') format('woff');
	font-weight:  500;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Graphik Web';
	src: url('../font/Graphik-Regular-Web.eot');
	src: url('../font/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
	url('../font/Graphik-Regular-Web.woff') format('woff');
	font-weight:  400;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Graphik Web';
	src: url('../font/Graphik-Light-Web.eot');
	src: url('../font/Graphik-Light-Web.eot?#iefix') format('embedded-opentype'),
	url('../font/Graphik-Light-Web.woff') format('woff');
	font-weight:  300;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'Dala Floda';
	src: url('../font/DalaFloda-MediumItalic.eot');
	src: url('../font/DalaFloda-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../font/DalaFloda-MediumItalic.woff') format('woff'),
		url('../font/DalaFloda-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Dala Floda';
	src: url('../font/DalaFloda-Medium.eot');
	src: url('../font/DalaFloda-Medium.eot?#iefix') format('embedded-opentype'),
		url('../font/DalaFloda-Medium.woff') format('woff'),
		url('../font/DalaFloda-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
