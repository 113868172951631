.top-slider-wrap {
	@include below($s) {
		margin-bottom:0px;
	}
}
.videoWrapper {
	position: relative;
	padding-bottom: 41.85%;
	width: 100%;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@include below(500px) {
		padding-bottom: 90%;
	}
}

.swiper-slide {
	overflow: hidden;
}
.top-slider-content {
	position: absolute;
	bottom:0;
	width:$contentWidth;
	max-width:85%;
	left:50%;
	transform:translateX(-50%);
	padding-bottom:50px;
	@include below($s) {
		top:40%;
		max-width:100%;
		bottom:0;
	}
}
.instance-1 .top-slider-content {
	@include below($s) {
		top:auto;
		bottom:10px;
	}
}
.top-slider-caption {
	padding:20px;
	transition: background .2s;
	background:rgba(20, 130, 250, 0.75);
	max-width:580px;

	a {
		color:white;
		text-decoration:underline;
		font-weight:500;
		margin-top:24px;
		display: inline-block;
		&:hover {
			opacity:0.5;
		}
	}
	@include below($l) {
		.title-large {
			font-size: 38px;
		}
	}
	@include below($m) {
		.title-large {
			font-size: 24px;
			line-height: 32px;
		}
	}
	&.reference {
		float:right;
		width:381px;
		max-width:100%;
		@include below($s) {
			width:100%;
			float:none;
			position: absolute;
			bottom:-5px;
			margin:0 auto;
		}
	}
}
.swiper-slide {
	@include below($s) {
		margin-top: 24px;
	}
}
.swiper-container:hover .swiper-navigation a {
	background-color:rgba(0,0,0,.75);
}
@include below($m) {
	.swiper-container .swiper-navigation a {
		background-color:rgba(0,0,0,.75);
	}
}
.swiper-navigation {
	position: absolute;
	width:100%;
	top:46%;
	left:50%;
	transform:translate(-50%, -50%);
	z-index:9;
	a {
		padding:10px;
		img {
			vertical-align: middle;
		}
	}
	// @include below($m) {
	// 	top: 83%;

	// 	a {
	// 		// padding: 10px;

	// 		img {
	// 			width: 26px;
	// 			height: 20px;
	// 		}
	// 	}
	// }
	@include below($s) {
		// top: 28%;

		a {
			padding: 10px;

			img {
				width: 26px;
				height: 20px;
			}
		}
	}

	.swiper-button-disabled {
		display: none;
	}
}
.js-prev-slide {
	position: absolute;
	left:1%;
	img {
		transform:rotate(180deg);
		width:32px;
		height:25px;
	}
	@include below($s) {
		// display: none;
		left: 2%;
	}
}

.js-next-slide {
	position: absolute;
	right:1%;
	img {
		width:32px;
		height:25px;
	}
	@include below($s) {
		right: 2%;
	}
}

.reference-info {
	margin: 10px 0 -12px;
	width:100%;

	@include below($s) {
		margin-top: 0;
	}
}