.project-list, .area-list {
	width:100%;
	display: block;
	position: relative;

}
.list {
	margin-left:-20px !important;
	margin-right:-20px !important;
}

.project-list-item {
	position: relative;
	margin: 12px 20px;
	list-style: none;
	overflow: hidden;
    width: calc((100% / 3) - 40px);
	@include below(950px) {
		width: calc(100% / 2);
	}
	@include below(750px) {
		width:100%;
	}
	.text-holder {
	    width: 68%;
	    display: inline-block;
	    vertical-align: top;
	}
	.reference-info  {
		position: relative;
		.reference-info-item {
			margin-right:15px;

			&:last-of-type {
				margin-right:0px;
			}
		}
	}
	img.icon {
		margin: 10px 0 0 0;
		position: relative;
	}
	img {
		transition: .4s;
	}
	&:hover {

		img {
			opacity: .9;
		}

		.listed-project-caption:before {
			opacity: .9;
		}
	}
}
.full-width-reference {
	margin-top:calc($gutter / 2);
	@include below($m) {
		margin-top:0px;
	}
}
.listed-project-caption {
	width:100%;
	max-width:100%;
	position: relative;
	bottom:0px;
	background: $primaryBlue;
	background: $white;
	min-height: 184px;
	height: 100%;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $primaryBlue;
		transition: .2s;
	}
	h2, p {
		position: relative;
	}
	a {
		position: relative;
		margin-top: 4px;
	}
	@include below($s) {
		right:0;
		left:0;
		bottom:0;
		a {
			margin-top: 24px;
			display: block;
		}
	}
}
.project-banner {
	background:$black;
	position: absolute;
	top: 0px;
	left: 0;
	z-index: 100;
	padding: 9px 15px 7px 15px;
}
.featured-project .project-banner {
	left:0px;
}
.js-toggle-project-filter {
	position: relative;
	font-size:18px;
	font-weight:$medium;
	&:after {
		content:'Filtrera projekt';
	}
	&.active {
		background:$primaryBlue;
		color:white;
		opacity:1;
		padding:10px 20px 10px 0;
		&:after {
			content:'Stäng filtrering';
			text-decoration: underline;
		}
		&:before {
			content:'';
			width:20px;
			background:$primaryBlue;
			height:100%;
			position:absolute;
			top:0;
			bottom:0;
			left:-20px;
			display: block;
			visibility: visible;
		}
	}
}