body {

	@include below($l) {
		padding-top: 50px;
	}
}

.is-main{
	font-weight: bold;
}

.desktop-nav {
	position: sticky;
	top: 0;
    z-index: 10000;
	background: white;
	padding: 0 56px;
	border-bottom: 1px solid #979797;
	align-items:flex-start;
	transition: .15s;

	@include below($l) {
		display: none !important;
	}

	.logo {

		img {
			width: 239px;
			margin: auto 0;
		}
	}

	.menu-main-container {
		align-items: center;
    	display: flex;

		.menu {
			display: inline-block;
		}

		.menu-item {
			display: inline-block;
			padding: 0 25px;
			vertical-align: top;
			text-align: left;
			cursor: pointer;

			&:hover > a,
			&.current-menu-item > a,
			&.current-menu-ancestor > a {
				padding-bottom: 20px;
				border-bottom: 4px solid $primaryBlue;
			}

			> a {
				display: inline-block;
				line-height: 48px;
				padding: 24px 0;
			}
			
		}

		.sub-menu {
			z-index: 100;
			overflow: hidden;
			transition: 0s;
			padding: 0;
			position: absolute;
			left: 0;
			right: 0;
			display: block;
			opacity: 0;
			pointer-events: none;
			transition: .15s;

			.holder{
				padding-left: 0;
				padding-right: 0;
			}

			.menu-bg {
				background: $white;
				margin-top: 1px;
				border-bottom: 1px solid #D9D9D9;
			}
			
			.menu-item > a{
				line-height: 36px;
			}

			.two-cols{
    			max-height: 190px;
				display: flex;
				flex-flow: column wrap;
			}
			.row-3{
				max-height: 140px;
			}
			.row-4{
				max-height: 230px;
			}
			.row-5{
				max-height: 270px;
			}
			.row-6{
				max-height: 310px;
			}
			.row-7{
				max-height: 255px;
			}
			.row-8{
				max-height: 320px;
			}
			.row-9{
				max-height: 430px;
			}
			
			.menu-item {
				margin: 0;
				padding: 0;
				display: block;

				a {
					color: #000;
					padding: 0;

					&:after {
						display: none;
					}
				}

				&.bold {

					a {
						font-weight: bold;
					}
				}

				&:hover > a,
				&.current-menu-item > a {
					text-decoration: underline;
					padding-bottom: 0;
					border-bottom: none;
				}
			}

		}

		.menu-item-has-children {

			a:after {
				content: '';
				display: inline-block;
				width: 12px;
				height: 7px;
				background-image:url('../img/icon-dropdown-blue.svg');
				background-size: contain;
				background-repeat: no-repeat;
				margin-top: 21px;
				margin-left: 7px;
				vertical-align: top;
			}

			&:hover {

				.sub-menu {
					z-index: 1000;
					opacity: 1;
					pointer-events: initial;
					transition: .1s;
				}
			}
		}
	}
}

/* Global menu */
.menu-global-container {

	.menu-item {
		transition: .1s;

		a {
			display: inline-block;
			line-height: 48px !important;
			font-size: 12px;
			font-weight: $regular;
			color: #4A4A4A;
		}

		&.icon-profile a {

			&:before {
				content: '';
				display: inline-block;
				width:13px;
				height:15px;
				background-image:url('../img/icon-profile-black.svg');
				background-size: contain;
				background-repeat: no-repeat;
				margin-top: 16px;
				margin-right: 8px;
				vertical-align: top;
			}
		}

		&:hover {

			a {
				text-decoration: underline;
			}
		}
	}

	.desktop-nav & {

		.menu-item {
			display: block;
			margin-left: 0;

			a {
				line-height: 24px !important;
			}

			&.icon-profile a:before {
				margin-top: 4px;
			}

			&.current_page_item {

				a {
					color: $primaryBlue;
				}

				&.icon-profile a:before {
					background-image:url('../img/icon-profile-blue.svg');
				}
			}
		}
	}

	footer & {
		float: right;
		text-align: right;

		.menu-item {
			display: inline-block;
			margin-left: 16px;

			a {
				font-weight: $semibold;
				color: #fff;
			}

			&.icon-profile a:before {
				background-image:url('../img/icon-profile.svg');
			}
		}
	}

	/* Mobile */
	.mobile-nav & {
		float: none;
		text-align: center;

		.menu-item {
			margin-left: 8px;
			margin-right: 8px;
		}
	}
}

/* Mobile menu */
.mobile-nav {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 50px;
	background: $white;
	z-index: 1000;
	transition: .5s;
	display: none;

	@include below($l) {
		display: block;
	}

	.bg-raster {
		height: 50px;
		z-index: 99;
		opacity: 1;
		transition: .2s;
	}

	.nav-top {
		height: 50px;
		z-index: 100;
		background: $white;

		&.holder {
			padding: 10px 20px;
		}

		.logo {
			display: inline-block;
			height: 30px;

			img {
				height: 100%;
			}
		}

		.show-menu {
			position: absolute;
			top: 0;
			right: 0;
			width: 50px;
			height: 50px;

			> span {
				position: absolute;
				top: 25px;
				right: 19px;
				width: 25px;
				height: 2px;
				background-color: #979797;

				&:before,
				&:after {
					content: '';
					position: absolute;
					background-color: #979797;
					width: 25px;
					height: 2px;
					top: -9px;
					left: 0;
				}

				&:after {
					top: 9px;
				}
			}
		}
	}

	.menu-container {
		background: $white;
		opacity: 0;
		transform: translateY(-100%);
		transition: opacity .3s, transform .5s;
	}

	/* Main */
	.menu-main-container {

		.menu-item {
			padding: 0 20px;
			border-bottom: 1px solid #D9D9D9;
			position: relative;
			color: $black !important;

			&:first-of-type {
				border-top: 1px solid #D9D9D9;
			}

			a {
				padding: 17px 0;
				display: inline-block;
				font-size: 16px;
			}
		}

		a.show-sub {
			width: 40px;
			height: 40px;
			padding: 8px;
			position: absolute;
			right: 12px;
			top: 6px;

			.circle {
				border: 2px solid $primaryBlue;
				border-radius: 100%;
				width: 24px;
				height: 24px;
				position: absolute;

				> span {

					&:before,
					&:after {
						content: '';
						position: absolute;
						width: 12px;
						height: 2px;
						top: 9px;
						left: 4px;
						background-color: $primaryBlue;
					}

					&:after {
						transform: rotate(90deg);
					}
				}
			}
		}

		.sub-menu {
			display: none;

			.menu-item {
				border: none;
				padding: 0;

				&:last-of-type {
					margin-bottom: 16px;
				}

				a {
					font-size: 14px;
					padding: 8px 0;
				}

				&.current-menu-item a {
					text-decoration: underline;
				}
			}
		}

		.menu-item.active {

			.show-sub .circle span:after {
				display: none;
			}

			> .sub-menu {
				display: block;
			}
		}
	}

	&.active {
		height: auto;
		bottom: 0;
		overflow: scroll;

		.bg-raster {
			opacity: 0;
		}

		.nav-top .show-menu {

			> span {
				background-color: transparent;

				&:before,
				&:after {
					top: 0;
					transform: rotate(45deg);
				}

				&:after {
					top: 0;
					transform: rotate(-45deg);
				}
			}
		}

		.menu-container {
			opacity: 1;
			transform: translateY(0%);
		}
	}
}