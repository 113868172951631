.line-box {
	position: relative;
	padding-top:24px;
	display: block;
	a, p {
		text-decoration: none;
	}
	.link {
		text-decoration: underline;
	}
	&:before {
		@include pre-line($black, 5px);
	}
	&.blue:before {
		@include pre-line($primaryBlue, 5px);
	}
}

.line-box-image {
	width:100%;
	height:auto;
	margin:12px 0;
	display: block;
}

.play-wrap {
	position: relative;
}
.play-icon {
	position: absolute;
	top:50%;
	left:50%;
	transform:translate(-50%, -50%);
	width:88px;
}