.project-item-holder{
	display: block;
	max-width: 90%;
	width:100%;
	.inner {
		display:flex;
		flex-wrap:wrap;
		justify-content: space-between;
		width:100%;
		@include below($s) {
			flex-direction: column;
		}
	}

}
.project-item{
	position: relative;
	color: #fff;
	text-decoration: none;
	margin-top: $gutter / 2;
	margin-bottom: $gutter / 2;
	display:block;
	width:31.5%;
	overflow: hidden;

	@include below($m) {
		width:49%;
	}
	@include below($s) {
		width:100%;
	}

	img{
		width:100%;
		display: block;
		transition: .4s;
	}

	.label{
		position: absolute;
		top: 0;
		left: 0;
		font-size: 16px;
		padding: 8px 18px;
		background: #000;
		color: #fff;
		font-weight: 600;
		z-index: 1;
	}

	.meta{
		padding: $gutter $gutter/2;
		background: $white;
		position: relative;
		height: 100%;

		.title-small {
			color: #fff;
			margin-bottom: 8px;
			position: relative;
		}

		.type {
			position: relative;
		}

		.external {
			position: absolute;
			right: $gutter/2;
			bottom: $gutter/2;

			&:hover {
				text-decoration: underline;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: $primaryBlue;
			transition: .1s;
		}
	}

	&:hover {

		img {
			opacity: 0.9;
		}

		.meta:before {
			opacity: .9;
		}
	}
}